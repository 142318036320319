import { dfmAlert } from "~/public_html/dfm5";
import { buildErrorMessageHtml, getReadFriendlyErrorMessage } from "./errors";

export async function getCourse(coid) {
  try {
    const response = await fetch(`/api/course/course/${coid}`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const unit = await response.json();
    return unit;
  } catch (e) {
    console.log(e.message);
    const readFriendlyErrorMessage = getReadFriendlyErrorMessage(e.message) ?? "There was an error retrieving this course.";
    const errorMessageHtml = buildErrorMessageHtml(readFriendlyErrorMessage, null);
    dfmAlert(`<div style="margin: 12px 0">${errorMessageHtml}</div>`);
  }
}

export async function getUnit(cuid, includeInactiveSubskills = true) {
  try {
    const url = includeInactiveSubskills ? `/api/course/explore/${cuid}` : `/api/course/unit/${cuid}`
    const response = await fetch(url);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const unit = await response.json();
    return unit;
  } catch (e) {
    console.log(e.message);
    const readFriendlyErrorMessage = getReadFriendlyErrorMessage(e.message) ?? "There was an error retrieving this course unit.";
    const errorMessageHtml = buildErrorMessageHtml(readFriendlyErrorMessage, null);
    dfmAlert(`<div style="margin: 12px 0">${errorMessageHtml}</div>`);
  }
}

export async function getAllSkillsCourseDetails() {
  try {
    const response = await fetch(`/api/course/course/allskills/details`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const course = await response.json();
    return course;
  } catch (e) {
    console.log(e.message);
    dfmAlert("There was an error retrieving the all skills course.");
  }
}

export async function getUserCourses(uid) {
  try {
    const response = await fetch(`/api/user/${uid}/courses`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const { courses } = await response.json();
    /**
     * This API endpoint returns a course for each class allocation.
     * Therefore it's possible for a user to belong to multiple classes
     * that have the same course allocated. As we just want to return
     * a list of unique courses, we'll filter out any duplicates.
     */
    return courses.reduce((acc, course) => {
      if (!acc.some((c) => c.coid === course.coid)) {
        acc.push(course);
      }
      return acc;
    }, []);
  } catch (e) {
    console.log(e.message);
    dfmAlert("There was an error retrieving your user courses.");
  }
}

export async function getUserCoursesByClass(uid) {
  try {
    const response = await fetch(`/api/user/${uid}/courses`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const { courses } = await response.json();
    return courses.reduce((acc, course) => {
      const { classes = [], ...rest } = course;
      const courseClassName = classes[0];
      if (courseClassName) {
        const existingClass = acc.find((cl) => cl.className === courseClassName);
        if (existingClass) {
          existingClass.courses.push(rest);
        } else {
          acc.push({ className: courseClassName, courses: [rest] });
        }
      }
      return acc;
    }, []);
  } catch (e) {
    console.log(e.message);
    dfmAlert("There was an error retrieving your user courses by class.");
  }
}

export async function getSchoolCourses({ getClasses = false, sid }) {
  try {
    const response = await fetch("/api/course/school/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ classes: getClasses, sid }),
    });
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const { courses } = await response.json();
    return courses;
  } catch (e) {
    console.log(e.message);
    dfmAlert("There was an error retrieving your school courses.");
  }
}

export async function getAllPublishedCourses() {
  try {
    const response = await fetch("/api/courses/published/all");
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const authors = await response.json();
    return authors;
  } catch (e) {
    console.log(e.message);
    dfmAlert("There was an error retrieving the published courses.");
  }
}

export async function getPublishedCourses(coids) {
  try {
    const params = new URLSearchParams({ coids: coids.join(",") });
    const response = await fetch(`/api/courses/published/selection?${params.toString()}`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const authors = await response.json();
    return authors;
  } catch (e) {
    console.log(e.message);
    dfmAlert("There was an error retrieving the selection of published courses.");
  }
}

export async function getCourseGroupsForDropdown({
  user = null,
  preselectedCourse = null,
} = {}) {
  // If there is a logged in user, get their selection of courses.
  const uid = user?.uid;
  const userCourses = uid ? await getUserCourses(uid) : [];

  // If they belong to a school, get the school selection of active courses, without duplicates of above.
  const sid = user?.sid;
  const schoolCourses = sid ? await getSchoolCourses({ sid }) : [];
  const schoolCoursesFiltered = schoolCourses?.filter((course) => {
    const inUserCourses = userCourses.some(
      (c) => c.coid === course.coid,
    );
    const isActive = course.status === 1 || course.status === 3;
    return !inUserCourses && isActive;
  }) ?? [];

  // Get the 'All Skills' course that will display for all users, unless it's already in the user or school courses.
  const globalCourses = []
  const allSkillsCourseInUserCourses = userCourses.some(course => course.type === "ALL_SKILLS");
  const allSkillsCourseInSchoolCourses = schoolCoursesFiltered.some(course => course.type === "ALL_SKILLS");
  if (!allSkillsCourseInUserCourses && !allSkillsCourseInSchoolCourses) {
    const allSkillsCourse = await getAllSkillsCourseDetails();
    globalCourses.push(allSkillsCourse);
  }

  // If a preselected course has been passed to this function, check it isn't already included above.
  const allCoursesSoFar = [
    ...userCourses,
    ...schoolCoursesFiltered,
    ...globalCourses,
  ];
  const currentlyViewingCourse =
    preselectedCourse &&
    !allCoursesSoFar.some((c) => c.coid === preselectedCourse.coid)
      ? preselectedCourse
      : null;

  // Set up the course groups for the dropdown menu.
  const courseGroups = [
    {
      id: "current",
      heading: "Currently viewing",
      courses: currentlyViewingCourse ? [currentlyViewingCourse] : [],
    },
    {
      id: "user",
      heading: "Your courses",
      courses: userCourses,
    },
    {
      id: "school",
      heading: "School courses",
      courses: schoolCoursesFiltered,
    },
    {
      id: "all",
      heading: "All skills",
      courses: globalCourses,
    },
  ];

  return courseGroups;
}

export async function getResourceCountForCourse(coid) {
  try {
    const response = await fetch(`/api/course/course/${coid}/resourcecount`);
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const counts = await response.json();
    return counts;
  } catch (e) {
    console.log(e.message);
    dfmAlert(
      "There was an error retrieving the resource count for this course.",
    );
  }
}

/**
 * Retrieves the recently viewed course ids for a user
 * from local storage.
 *
 * @typedef {Object} Course
 * @property {number} coid
 * @property {string} authorName
 * @property {string} name
 * @property {number | undefined} numberOfModules
 * @property {string | undefined} thumb
 * @property {string} type
 *
 * @param {number} uid
 * @returns {Course[]}
 */
export function getRecentlyViewedCourses(uid) {
  const key = `recentlyViewedCourses_${uid}`;
  const courses = JSON.parse(localStorage.getItem(key)) || [];
  return courses
    .filter(course => course.coid && course.authorName && course.name && course.type)
    .map(({ coid, authorName, name, numberOfModules, thumb, type }) => ({ coid, authorName, name, numberOfModules, thumb, type }))
    .slice(0, 3);
}

/**
 * Sets a course id as the most recently viewed course
 * for a user in local storage. Keeps a maximum of 3
 * entries.
 *
 * @param {number} uid
 * @param {Course} course
 */
export function updateRecentlyViewedCourses(uid, course) {
  const key = `recentlyViewedCourses_${uid}`;
  const courseObject = {
    coid: course.coid,
    authorName: course.authorName,
    name: course.name,
    type: course.type,
  };
  if (course.thumb) {
    courseObject.thumb = course.thumb;
  }
  if (course.numberOfModules) {
    courseObject.numberOfModules = course.numberOfModules;
  }
  let courses = getRecentlyViewedCourses(uid);
  courses = courses.filter(course => course.coid !== courseObject.coid);
  courses.unshift(courseObject);
  courses = courses.slice(0, 3);
  localStorage.setItem(key, JSON.stringify(courses));
}